<template>
  <div>
    <div class="section-title section-title_app">
      <h3>Ordenes por #Toma</h3>
    </div>
    <div class="columns is-multiline is-gapless">
      <div class="column is-12 has-background-white">
        <div class="mt-4 p-3">
          <ForeignWorkSearch @select="handleSelectForeignWork"/>
        </div>
        <div  class="pt-3 pl-3 pr-3" v-if="foreignWork">
          <p><strong>Empresa: </strong> {{foreignWork.company.code}} - {{foreignWork.company.name}}</p>
        </div>
        <div class="pt-3 pl-3 pr-3" v-if="foreignWork">
          <b-button type="is-primary" @click="handlePrint">Imprimir Etiquetas</b-button>
          <b-button type="is-link" class="ml-3" @click="handleSelectAll">Seleccionar todas</b-button>
        </div>

        <div class="table-container mt-3 p-3" v-if="foreignWork">
          <table class="table is-fullwidth">
            <thead>
            <tr>
              <th></th>
              <th>Orden</th>
              <th>Paciente</th>
              <th>Estado</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row, index) in orders" :key="index">
              <td><b-checkbox v-model="selectedOrders[`o_${row.id}`]"/></td>
              <td>{{row.order}}</td>
              <td>{{row.patient.full_name}}</td>
              <td>{{row.is_cancel ? 'Activa': 'Cancelada'}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>


</template>

<script>

import ForeignWorkSearch from "../../components/foreign-work/ForeignWorkSearch.vue";

export default {
  components: {ForeignWorkSearch},
  data() {
    return {
      orders: [],
      selectedOrders: {},
      foreignWork: null
    }
  },
  computed: {
    orderIds(){
      const result = [];
      for (const id in this.selectedOrders){
        if(this.selectedOrders[id]){
          result.push(parseInt(id.replace('o_', '')));
        }
      }
      return result;
    }
  },
  mounted() {
    this.wrapper = document.getElementById('main-wrapper');
    this.wrapper.classList.add('is-expanded');
  },
  methods: {
    handleSelectForeignWork(value) {
      this.foreignWork = value;
      this.getOrders(value.id)
    },
    getOrders(foreignWorkId) {
      this.$loader.show();
      const params = {
        "foreign-work": foreignWorkId
      }
      this.selectedOrders = {};
      this.orders = [];
      this.$http.get('/foreign-work-orders', {params}).then(({data}) => {
        const orders = data.data;
        const selectedOrders = {};
        for(const i of orders){
          selectedOrders[`o_${i.id}`] = true;
        }
        this.selectedOrders = selectedOrders;
        this.orders = orders;
      }).finally(() => {
        this.$loader.hide();
      })
    },
    handlePrint(){
      console.log(this.orderIds);
      this.$loader.hide();
      this.$http.post('/foreign-work-orders/print', {
        orders: this.orderIds
      }).then(() => {
        this.$buefy.dialog.alert({
          title: 'Impresión realizada',
          message: 'Se enviarón a imprimir las etiquetas con éxito',
          type: 'is-success',
          hasIcon: true,
          icon: 'check',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }).finally(() => {
        this.$loader.hide();
      })
    },
    handleSelectAll() {
      this.$alfalab.enabledOrdersByForeignWork()
      const selectedOrders = {};
      for(const i of this.orders){
        selectedOrders[`o_${i.id}`] = true;
      }
      this.selectedOrders = selectedOrders;
    }
  }
}
</script>


<style scoped>

</style>