<template>
  <div>
    <div class="columns">
      <div class="column is-12">
        <b-field label="Toma">
          <b-autocomplete
              v-model="name"
              placeholder=""
              :open-on-focus="true"
              :data="data"
              field="descrip"
              @select="handleSelect"
              :clearable="true"
              @input="handleChange"
              :loading="loading"
          >
          </b-autocomplete>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default  {
  props: {

  },
  data() {
    return {
      data: [],
      name: '',
      loading: true
    }
  },
  computed: {
  },
  methods: {
    handleSelect(option) {
      this.$emit("select", option);
    },
    handleChange(value) {
      this.loading = true;
      const params = {
        perPage: '*',
        name: value,
        limit: 50
      }
      this.$http.get('/foreign-work', {params}).then(({data}) => {
        this.data = data.data;
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>


<style scoped>

</style>